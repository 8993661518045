import * as React from "react";

import { Box, Button, InputBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import AddIcon from "@mui/icons-material/Add";

import PartnerNavbar from "../../components/PartnerNavbar";
import PinkDeal from "../../components/PinkDeal";
import PartnerFooter from "../../components/PartnerFooter";

interface BennieHealthProps {}

const Search = styled("div")(({ theme }) => ({
  position: "relative",

  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  marginTop: "2.5rem",
  width: "90% !important",
  height: "4rem",
  borderRadius: "9999px",
  border: "1px solid gray",
  display: "flex",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  height: "80%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    height: "100%",
    fontSize: "1.25rem",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

const BennieHealth: React.FC<BennieHealthProps> = () => {
  const headerimg = require("../../assets/PartnerHeroBg.png");
  const logo = require("../../assets/HwPartnerLogo.png");
  const bennieLogo = require("../../assets/BennieLogo.png");
  const img1 = require("../../assets/BenniePHImg1.png");
  const img2 = require("../../assets/BenniePHImage2.png");
  const img3 = require("../../assets/BenniePHImage3.png");
  const img4 = require("../../assets/BenniePHImage4.png");
  const img5 = require("../../assets/BenniePHImage5.png");
  return (
    <Box
      sx={{
        // height: "fit-content",
        width: "100vw",
        backgroundColor: "white",

        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 ",
      }}
    >
      {/* header */}
      <Box
        sx={{
          width: "100%",
          height: "50rem",
          backgroundColor: "white",
        }}
      >
        {/* nav */}
        <PartnerNavbar color="black" />

        {/* hero */}

        <Box
          sx={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minHeight: "40rem",
            backgroundColor: "white",
            backgroundImage: `url(${headerimg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderBottom: "1px solid lightgray",
          }}
        >
          <Box
            sx={{
              width: "32%",
              height: "30rem",
              position: "relative",
              left: "10%",
              textAlign: "left",
            }}
          >
            {/* logos */}

            <Box
              sx={{
                width: "100%",
                height: "3rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                margin: "0 auto 2.5rem auto",
              }}
            >
              <img
                src={bennieLogo}
                alt="Bennie health logo"
                style={{ maxWidth: "25%", height: "auto" }}
              />

              <AddIcon
                sx={{ margin: "0 1rem .25rem 1rem", opacity: 0.25 }}
                fontSize="medium"
              />

              <img
                src={logo}
                alt="Herewith logo"
                style={{ maxWidth: "25%", height: "auto" }}
              />
            </Box>
            <h1 style={{ color: "#C97B99" }}>
              Finding <i> everyday help</i> for all our residents
            </h1>
            <p
              style={{
                fontSize: "1rem",
                lineHeight: 1.75,
                textAlign: "left",
                width: "95%",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              With no hourly minimums or subscription fees, book
              background-checked Helpers online, and get customizable support
              with tasks, like meal preparation and errands, or long term
              caregiving.
            </p>

            {/* searchbar */}

            <Search>
              <SearchIconWrapper>
                <LocationOnRoundedIcon
                  style={{ fontSize: "2rem", color: "#177A98" }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Enter your zip code"
                inputProps={{ "aria-label": "search" }}
              />
              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "9999px",
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a helper
              </Button>
            </Search>
          </Box>
        </Box>
      </Box>
      <PinkDeal />
      <img src={img1} alt="img1" style={{ width: "100%" }} />
      <img src={img2} alt="img2" style={{ width: "100%" }} />{" "}
      <img src={img3} alt="img3" style={{ width: "100%" }} />{" "}
      <img src={img4} alt="img4" style={{ width: "100%" }} />
      <img src={img5} alt="img5" style={{ width: "100%" }} />
      <PartnerFooter />
    </Box>
  );
};

export default BennieHealth;
