import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { styled } from "@mui/material/styles";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

const steps = [
  {
    title: "Create a Help Request",
    text: "Request a Helper for one-time tasks or ongoing help",
    step: 1,
    color: "#C9BFE8",
  },
  {
    title: "Book your Helper",
    text: "Chat with applicants and choose your favorite Helper to work with",
    step: 2,
    color: "#FFE0EC",
  },
  {
    title: "Pay-as-you-go",
    text: "Only pay for the help you receive, with no commitments or subscriptions",
    step: 3,
    color: "#C0E4EB",
  },
];

interface StepLabelIconProps {
  step: number;
  color: string;
}
const StepLabelIcon: React.FC<StepLabelIconProps> = ({ step, color }) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{
        width: phone ? "75px" : "60px",
        height: phone ? "75px" : "60px",
        borderRadius: "50%",
        backgroundColor: `${color}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        margin: phone ? "1rem auto" : "auto",
      }}
    >
      <p style={{ fontWeight: "bold" }}>{step}</p>
    </div>
  );
};

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
    zIndex: "1",

    margin: "0 10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

interface FastBookingProps {}

const FastBooking: React.FC<FastBookingProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "28rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundColor: "white",
          padding: "1rem auto",
          paddingTop: phone ? "2rem" : "none",
          paddingBottom: phone ? "2rem" : 0,
        }}
      >
        <h1>
          <span style={{ fontStyle: "italic" }}>Fast</span>, convenient booking
        </h1>

        <Stepper
          alternativeLabel
          sx={{
            minWidth: "80%",

            width: "fit-content",
          }}
          connector={<Connector />}
          orientation={phone ? "vertical" : "horizontal"}
        >
          {steps.map((label: any) => (
            <Step
              key={label}
              active
              sx={{
                justifyContent: "center",
                alignItems: "flex-start",
                minHeight: "12rem",
                textAlign: "center",
                marginTop: phone ? "1rem " : "auto",
              }}
            >
              <StepLabel
                StepIconComponent={() => (
                  <StepLabelIcon step={label.step} color={label.color} />
                )}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",

                    lineHeight: "1.5",
                  }}
                >
                  <h3 style={{ fontSize: "1.2rem", color: "#0C3D4C" }}>
                    {label.title}
                  </h3>

                  <p
                    style={{
                      fontSize: phone ? "1.25rem" : "1rem",
                      width: phone ? "90%" : "70%",
                    }}
                  >
                    {label.text}
                  </p>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default FastBooking;
