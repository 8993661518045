import * as React from "react";
import { Box, Divider } from "@mui/material";

import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
import EventsHeader from "./EventsHeader.tsx";
import TrustedLocal from "./TrustedLocal.tsx";
import FastConvenient from "./FastConvenient.tsx";
import HelpYouNeed from "./HelpYouNeed.tsx";
import WeCanHelpEvents from "./WeCanHelpEvents.tsx";
import EventsTestimonials from "./EventsTestimonials.tsx";
import Testimonials from "../Dashboard/Testimonials.tsx";
// import ZeroCost from "./ZeroCost.tsx";
// import CostlyHidden from "./CostlyHidden.tsx";
// import ELHeader from "./ELHeader.tsx";
// import EverydayRelief from "./EverydayRelief.tsx";
// import IconRepeater from "./IconRepeater.tsx";
// import GetinTouch from "./GetinTouch.tsx";

interface EventsLandingProps {}

const EventsLanding: React.FC<EventsLandingProps> = () => {
  const [utm, setUTM] = React.useState("");
  const img1 = require("../../assets/stats.png");
  const img2 = require("../../assets/pink.png");

  const img3 = require("../../assets/partnerlogosbanner.png");
  const img4 = require("../../assets/about4.png");

  const mobileImg1 = require("../../assets/MobileAbt1.png");
  const mobileImg2 = require("../../assets/MobileAbt2.png");
  const mobileImg3 = require("../../assets/MobileAbt3.png");
  const mobileImg4 = require("../../assets/MobileAbt4.png");

  const ban = require("../../assets/partnerbanner.png");
  const logo = require("../../assets/Logo.png");

  // http://localhost:3000/events/?utm_source=partner&utm_medium=qr-code&utm_campaign=fall-partner&utm_id=test
  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <EventsHeader />
        <FastConvenient />
        <TrustedLocal />
        <WeCanHelpEvents />
        <HelpYouNeed />
        {/* <EventsTestimonials /> */}
        <Testimonials />
        <Footer />
      </Box>
    </>
  );
};

export default EventsLanding;
