import SvgIcon from "@mui/material/SvgIcon";

export default function LinkedinIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 3.5C6.5 4.88071 5.38071 6 4 6C2.61929 6 1.5 4.88071 1.5 3.5C1.5 2.11929 2.61929 1 4 1C5.38071 1 6.5 2.11929 6.5 3.5Z"
          fill="black"
        />
        <path d="M6.5 8V22H1.5V8H6.5Z" fill="black" />
        <path
          d="M8 8V22H12.5V13C12.6667 12.5 13.4 11.5 15 11.5C16.6 11.5 17.3333 12.5 17.5 13V22H22V12.5C21.8333 10.8333 20.7 7.5 17.5 7.5C14.3 7.5 12.8333 8.83333 12.5 9.5V8H8Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}
