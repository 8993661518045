import * as React from "react";
import { Box, Button, Divider } from "@mui/material";

import ArrowButton from "../../components/ArrowButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
interface SideGigsProps {}

const SideGigs: React.FC<SideGigsProps> = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const icongrid = require("../../assets/cgicongrid.png");
  const mobileIcongrid = require("../../assets/cgicongridmobile.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "0 5rem",
          minHeight: "32rem",
          backgroundColor: "white",
          display: phone ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {/*-------------- Left column -------------- */}
          <Box
            sx={{
              width: "45%",
              padding: "3rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "left",
              textAlign: "left",
            }}
          >
            <h1 style={{ width: "85%", textAlign: "left", fontSize: "2.5rem" }}>
              {" "}
              <span style={{ fontStyle: "italic", color: "#1D99BE" }}>
                Side gigs or full time
              </span>
              , it’s your choice
            </h1>
            <p
              style={{
                width: "90%",
                textAlign: "left",
                fontSize: "1rem",
                lineHeight: 1.5,
                marginBottom: "1.5rem",
              }}
            >
              Choose your own clients and schedule, helping seniors in your
              community. With one-time tasks or full-time work, you can do as
              much or as little as you want.
            </p>

            <ArrowButton
              text="Find jobs near you"
              link="https://app.herewith.com/pro?utm_source=website_helper_page&utm_medium=cta&utm_campaign=helper_page&utm_id=helper_page_2024"
            />
          </Box>

          {/*-------------- Right column -------------- */}

          <Box style={{ minWidth: "55%", flex: 1 }}>
            <img src={icongrid} alt="icons" style={{ width: "100%" }} />
          </Box>
        </Box>

        <Divider
          variant="fullWidth"
          sx={{
            opacity: "0.5",
            position: "relative",
            top: "99%",
            display: "block",
            width: "100vw",
            transform: "translateX(-5%)",
          }}
        />
      </Box>

      {/* -------------- mobile ------------- */}

      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "white",
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem ",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            textAlign: "center",
            padding: "1rem 0",
            alignItems: "center",
          }}
        >
          <h1 style={{ width: "100%", fontSize: "2rem" }}>
            <span style={{ color: "#1D99BE", fontStyle: "italic" }}>
              Side gigs or full time,
            </span>{" "}
            <br />
            it's your choice
          </h1>
          <p
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.5",
              width: "90%",
              margin: "2rem auto",
            }}
          >
            Choose your own clients and schedule, helping seniors in your
            community. With our mix of one-time tasks and full-time work, you
            can do as much or as little as you want.
          </p>
          <Link
            style={{ width: "90%", height: "4rem" }}
            to="https://app.herewith.com/pro?utm_source=website_helper_page&utm_medium=cta&utm_campaign=helper_page&utm_id=helper_page_2024"
          >
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon fontSize="medium" />}
              sx={{
                width: "100%",
                height: "4rem",
                fontSize: "1.1rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Find jobs near you
            </Button>
          </Link>
          {/* <ArrowButton
            link="https://app.herewith.com/pro?utm_source=website_helper_page&utm_medium=cta&utm_campaign=helper_page&utm_id=helper_page_2024"
            text="Find jobs near you"
            mobileWidth="90% !important"
          /> */}
        </Box>
        <Box sx={{ width: "100%" }}>
          <img
            src={mobileIcongrid}
            alt="icons"
            style={{
              width: "100%",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SideGigs;
