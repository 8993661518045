import SvgIcon from "@mui/material/SvgIcon";

export default function CheckIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "50%",
      }}
    >
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#FFE0EC" />
        <path
          d="M14 15.5C14 14.9477 14.4477 14.5 15 14.5H33C33.5523 14.5 34 14.9477 34 15.5V23.0858C34 26.9083 32.1789 30.5022 29.0964 32.7627L24.5914 36.0663C24.2394 36.3245 23.7606 36.3245 23.4086 36.0663L18.9036 32.7627C15.8211 30.5022 14 26.9083 14 23.0858V15.5Z"
          fill="#996076"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.0498 19.8179C29.5203 20.2607 29.5427 21.001 29.0999 21.4715L23.274 27.7103C23.0529 27.9451 22.7447 28.0783 22.4222 28.0783C22.0996 28.0783 21.7914 27.9451 21.5703 27.7103L18.8179 24.7858C18.3752 24.3154 18.3976 23.5751 18.8681 23.1323C19.3385 22.6895 20.0788 22.712 20.5216 23.1824L22.4222 25.2018L27.3963 19.8681C27.839 19.3976 28.5794 19.3752 29.0498 19.8179Z"
          fill="#FFE0EC"
        />
      </svg>
    </SvgIcon>
  );
}
