import * as React from "react";
import { Typography } from "@mui/material";

interface FooterHeaderProps {
  text: string;
}

const FooterHeader: React.FC<FooterHeaderProps> = ({ text }) => {
  return (
    <Typography
      variant="h2"
      paragraph={true}
      sx={{
        textDecoration: "none",
        margin: "0 0 .5rem 0",
        color: "black",
        fontSize: "1.5rem",
        fontFamily: "SiteBody, sans-serif",
        fontWeight: "bold",
      }}
    >
      {text}
    </Typography>
  );
};

export default FooterHeader;
