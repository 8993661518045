import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";

import ArrowButton from "../../components/ArrowButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

interface CostlyHiddenProps {
  img: string;
  mobileImg: string;
  title1: string;
  title2: string;

  cta: string;
  // color of second line of the header
  subColor: string;
}

const CostlyHidden: React.FC<CostlyHiddenProps> = ({
  img,
  title1,
  title2,
  cta,
  subColor,
  mobileImg,
}) => {
  const [dense, setDense] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  // const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        width: phone ? "100%" : "80%",
        height: phone ? "fit-content" : "38rem",

        display: "flex",
        flexDirection: phone ? "column" : "row",
        justifyContent: "space-between",
        margin: phone ? "0 auto" : "3rem auto",
        paddingBottom: phone ? "2rem" : 0,
      }}
    >
      {/* text */}
      <Box
        sx={{
          width: phone ? "100%" : "40%",

          padding: phone ? "2rem 1.5rem 0 1.5rem" : 0,
          textAlign: "center",
          height: "100%",
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "2rem",
            }}
          >
            {title1}
          </h1>
          <h1
            style={{
              fontStyle: "italic",
              color: subColor,
              fontSize: "2rem",
            }}
          >
            {title2}
          </h1>
        </div>
      </Box>

      {/* desk */}
      <Box
        sx={{
          width: "40%",

          padding: "3rem 1rem",
          textAlign: "left",
          height: "100%",
          display: phone ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: phone ? "1.75rem" : "2rem",
              marginLeft: phone ? "1rem" : 0,
              marginBottom: "2rem",
            }}
          >
            {title1}{" "}
            <span
              style={{
                fontStyle: "italic",
                color: subColor,
                fontSize: phone ? "1.75rem" : "2rem",
                marginLeft: phone ? "1rem" : 0,
              }}
            >
              {" "}
              {title2}
            </span>
          </h1>
        </div>

        <ArrowButton text={cta} link="/partners#getintouch" />
      </Box>

      {/* image */}

      <Box
        sx={{
          width: phone ? "90%" : "65%",
          borderRadius: phone ? "none" : "3rem",
          height: phone ? "100vh" : "auto",
          backgroundImage: `url(${phone ? mobileImg : img})`,
          backgroundPosition: phone ? "center top" : "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: phone ? "cover" : "contain ",
          margin: phone ? "1rem auto" : 0,
          marginTop: phone ? "2rem" : 0,
        }}
      ></Box>
      <Box
        sx={{
          width: "90%",
          margin: "0 auto",
          display: phone ? "block" : "none",
        }}
      >
        <ArrowButton text="Get in touch" link="/partners#getintouch" />
      </Box>
    </Box>
  );
};

export default CostlyHidden;
