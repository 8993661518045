import * as React from "react";

import IconRepeater from "./IconRepeater.tsx";
import BookTrustedHelpers from "./BookTrustedHelpers.tsx";
import WeCanHelp from "./WeCanHelp.tsx";
import FastBooking from "./FastBooking.tsx";
import TrackHelpRequests from "./TrackHelpRequests.tsx";
import Subscribe from "../../components/Subscribe.tsx";
import Testimonials from "./Testimonials.tsx";
import { Box } from "@mui/material";
import Faqs from "../../components/Faqs.tsx";
import Partners from "../../components/Partners.tsx";
import Header from "./Header.tsx";
// import stepper from "../../assets/dashstep.png";
// import mod2 from "../../assets/mod2.png";

// interface DashboardProps extends RouteComponentProps {}
import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const headerimg = require("../../assets/hedimg.png");

  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100%",
          backgroundColor: "#FAF8F5",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        <Navbar color="#706D67" />
        <Header />
        {/* <img
            src={headerimg}
            alt="header image"
            style={{ width: "90%", margin: "1rem auto" }}
          /> */}
        <IconRepeater />

        <BookTrustedHelpers />
        <Partners />
        <WeCanHelp />
        <FastBooking />

        <TrackHelpRequests />
        {/* <Faqs /> */}
        <Testimonials />
        <Subscribe />
        <Footer />
      </Box>
    </>
  );
};

export default Dashboard;
