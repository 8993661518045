import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
const steps = [
  "Select master blaster campaign settings",
  "Create an ad group",
  "Create an ad",
];

interface WeCanHelpProps {}

const WeCanHelp: React.FC<WeCanHelpProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const icongrid = require("../../assets/icongrid.png");
  const mobileIcongrid = require("../../assets/MobileWeCanHelpIcons2.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          minHeight: "20rem",
          backgroundColor: "white",
          display: phone ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "3rem 5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-around",
            textAlign: "left",
            padding: "2rem 6rem",
          }}
        >
          <h1 style={{ width: "80%", fontSize: "2.5rem", lineHeight: "1.3" }}>
            We can help with that
          </h1>
          <p style={{ fontSize: "1.1rem", lineHeight: "1.5", maxWidth: "80%" }}>
            Whether it’s for yourself or a loved one, on Herewith you’ll find
            flexible, real-life answers for your day-to-day needs.
          </p>
          <Link to="https://app.herewith.com/?utm_source=website_home_page&utm_medium=cta&utm_campaign=home_page&utm_id=family_home_page_2024">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "70%",
                height: "3.5rem",
                fontSize: "18px",
                marginTop: "1rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Book a Helper today
            </Button>
          </Link>
        </Box>
        <Box sx={{ width: "60%" }}>
          <img src={icongrid} alt="icons" style={{ width: "100%" }} />
        </Box>
      </Box>

      {/* ------------- mobile ------------ */}
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "white",
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem ",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            textAlign: "center",
            padding: "1rem 0",
            alignItems: "center",
          }}
        >
          <h1 style={{ width: "100%" }}>
            We can help <br /> with that
          </h1>
          <p
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.5",
              width: "90%",
              margin: "1rem auto 2rem auto",
            }}
          >
            Whether it’s for yourself or a loved one, on Herewith you’ll find
            flexible, real-life answers for your day-to-day needs.
          </p>
          <Link to="https://app.herewith.com/?utm_source=website_home_page&utm_medium=cta&utm_campaign=home_page&utm_id=family_home_page_2024">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon fontSize="medium" />}
              sx={{
                width: "90%",
                height: "4rem",
                fontSize: "1.1rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Book a Helper today
            </Button>
          </Link>
        </Box>
        <Box sx={{ width: "100%" }}>
          <img
            src={mobileIcongrid}
            alt="icons"
            style={{
              width: "100%",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          />
        </Box>
      </Box>
      <Divider
        sx={{
          opacity: "0.5",
          width: "100%",
        }}
      />
    </>
  );
};

export default WeCanHelp;
