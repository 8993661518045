import * as React from "react";

import Button from "@mui/material/Button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
interface ArrowButtonProps {
  text: string;
  link: string;
  margin?: string;
  width?: string;
  mobileWidth?: string;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  text,
  link,
  margin,
  width,
  mobileWidth,
}) => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Link to={link}>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
        sx={{
          minWidth: phone ? "fit-content" : "40%",
          width: phone ? { mobileWidth } : { width },
          height: "3.5rem",
          fontSize: "18px",
          margin: { margin },
          "&:hover": {
            background: "#FECA5B",
            // Add other hover styles as needed
          },
        }}
      >
        {text}
      </Button>
    </Link>
  );
};

export default ArrowButton;
