import * as React from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "../../components/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function ELHeader() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const headerimg = require("../../assets/PartnerLandingHero.png");
  const mobileHeaderImg = require("../../assets/ElMobileHeader.png");
  return (
    <>
      <Navbar color="black" />
      <Box
        sx={{
          width: "100%",
          minHeight: phone ? "90vh" : "38rem",
          height: "fit-content",
          backgroundImage: `url(${phone ? mobileHeaderImg : headerimg})`,
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "2rem",
          paddingTop: "2rem",
        }}
      >
        <Box
          sx={{
            width: phone ? "85%" : "40%",
            minHeight: phone ? "fit-content" : "35rem",

            margin: phone ? "1rem auto" : "0 0 0 5rem ",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
          }}
        >
          <h1
            style={{
              marginBottom: phone ? "1rem" : "1.5rem",
              marginTop: phone ? "2rem" : "1.5rem",
              fontSize: phone ? "2rem" : "3.5rem",
            }}
          >
            Your employees{" "}
            <span style={{ fontStyle: "italic", color: "#177A98" }}>
              have a second job
            </span>
          </h1>
          <p
            style={{
              maxWidth: phone ? "100%" : "100%",
              fontSize: "1.1rem",
              lineHeight: 1.5,
            }}
          >
            Today 40 million Americans care for aging parents—while employers
            lose thousands of dollars every year per caregiving employee.
            Herewith’s flexible senior care benefits offer a real solution for
            you and your employees.
          </p>
          <Link to="/partners#getintouch">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#177A98",
                color: "white",
                marginTop: "2rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Get in touch
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}
