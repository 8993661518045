import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import PeopleIcon from "../../assets/icons/PeopleIcon.tsx";
import TimeIcon from "../../assets/icons/TimeIcon.tsx";
import SubscriptionIcon from "../../assets/icons/SubscriptionIcon.tsx";
import CheckIcon from "../../assets/icons/CheckIcon.tsx";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: ".25rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  width: "20%",
  height: "5rem",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

export default function IconRepeater() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const teal = require("../../assets/icons/CheckIcon.tsx");
  const bgimage = require("../../assets/PartnerPageIconsMobile.png");
  return (
    <>
      <div
        style={{
          display: phone ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EBE9E6  ",
          borderBottom: "1px solid #EBE9E6        ",
          backgroundColor: "white",
          // margin: "1rem 0 2rem 0",
          width: "100%",
          minHeight: "7rem",
        }}
      >
        <Divider
          variant="fullWidth"
          sx={{ color: "black", border: "black", zIndex: "1000" }}
        />
        <hr />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "85%",
            margin: "0 auto",
            justifyContent: "space-evenly",
          }}
        >
          <Item sx={{ backgroundColor: "white", height: "6rem" }} elevation={0}>
            <PeopleIcon />
            <p
              style={{
                marginLeft: "1rem",
                width: "70%",
                textAlign: "left",
                fontSize: "1.1rem",
              }}
            >
              1000s of Helpers available
            </p>
          </Item>
          <Item sx={{ backgroundColor: "white", height: "6rem" }} elevation={0}>
            <CheckIcon />
            <p
              style={{
                marginLeft: "1rem",
                width: "70%",
                textAlign: "left",
                fontSize: "1.1rem",
              }}
            >
              Fast, easy booking
            </p>
          </Item>
          <Item sx={{ backgroundColor: "white", height: "6rem" }} elevation={0}>
            <SubscriptionIcon />
            <p
              style={{
                marginLeft: "1rem",
                width: "70%",
                textAlign: "left",
                fontSize: "1.1rem",
              }}
            >
              Find help locally or remote
            </p>
          </Item>
          <Item sx={{ backgroundColor: "white", height: "6rem" }} elevation={0}>
            <TimeIcon />
            <p
              style={{
                marginLeft: "1rem",
                width: "70%",
                textAlign: "left",
                fontSize: "1.1rem",
              }}
            >
              Flexible options for everyone
            </p>
          </Item>
        </Stack>
        <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      </div>
    </>
  );
}
