import { hover } from "@testing-library/user-event/dist/hover";
import * as React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components";

interface LinkProps {
  text: string;
  url: string;
  color: string;
}

const Navlink: React.FC<LinkProps> = ({ text, url, color }) => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const styles = {
    textDecoration: "none",
    margin: "0 1rem",
    fontSize: "1.25rem",
    color: color,
  };
  return (
    <Link style={styles} to={url}>
      {text}
    </Link>
  );
};

export default Navlink;
