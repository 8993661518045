import * as React from "react";
import Box from "@mui/material/Box";
import Subscribe from "../../components/Subscribe";
import CgFaqs from "../../components/CgFaqs";
import CgPartners from "../../components/CgPartners";
import CgTestimonials from "./CgTestimonials";
import SideGigs from "./Sidegigs";
import Browse from "./Browse";
import QuickSimple from "./QuickSimple";
import Handle from "./Handle";
import Footer from "../../components/Footer";
import CgHeader from "./CgHeader";

import { styled, alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputBase from "@mui/material/InputBase";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Button } from "@mui/material";
const Search = styled("div")(({ theme }) => ({
  position: "relative",

  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  marginTop: "2.5rem",
  width: "50% !important",
  height: "4.5rem",
  borderRadius: "9999px",
  display: "flex",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "80%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    height: "100%",
    fontSize: "1.25rem",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));
interface CGHomeProps {}

const CaregiverHome: React.FC<CGHomeProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const footerimg = require("../../assets/curve.png");

  const icons = require("../../assets/cgicons.png");
  const mobileIcons = require("../../assets/MobileCgHomeIcongrid.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FAF8F5",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        <CgHeader />
        <Box
          sx={{
            width: "100%",
            height: phone ? "40vh" : "10rem",
            margin: "0 auto ",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#05242A",
            paddingBottom: "1rem",
          }}
        >
          <img
            src={phone ? mobileIcons : icons}
            alt="bg"
            style={{
              width: "80%",
            }}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <img
            src={footerimg}
            alt="bg"
            style={{
              width: "100%",
            }}
          />
        </Box>
        <SideGigs />
        <Browse />
        <QuickSimple />
        <Handle />
        <CgFaqs />
        <CgPartners />
        <CgTestimonials />
        <Subscribe />
        <Footer />
      </Box>
    </>
  );
};

export default CaregiverHome;
