import * as React from "react";
import Box from "@mui/material/Box";
import { Paper, Button } from "@mui/material";
import ArrowButton from "../../components/ArrowButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function Browse() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const map = require("../../assets/BrowseBackgroundImg.png");
  const mobileMap = require("../../assets/MobileBrowseBgImg.png");
  const curve = require("../../assets/mapbottom.png");
  const icon = require("../../assets/icons/BlueSearchIconCircle.png");
  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${phone ? mobileMap : map})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: phone ? "65vh" : "fit-content",
        minHeight: "38rem",
        backgroundColor: " #FAF8F5",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Paper
        sx={{
          height: phone ? "fit-content" : "50%",
          minHeight: "fit-content",
          width: phone ? "90vw" : "50%",
          borderRadius: "1rem",
          textAlign: "center",
          padding: phone ? "2.5rem 2rem" : "3rem",
          margin: "6rem auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={icon}
          style={{
            width: phone ? "4rem" : "5rem",
            height: "auto",
            marginBottom: phone ? "1.5rem" : "1rem",
          }}
          alt="icon"
        />
        <h1
          style={{
            marginBottom: phone ? "1.5rem" : "2rem",
            fontSize: phone ? "1.75rem" : "2.5 rem",
          }}
        >
          Browse Helper jobs{" "}
          <span style={{ fontStyle: "italic", color: "#6DA9B5" }}>
            near you
          </span>{" "}
        </h1>
        <ArrowButton
          text="See available requests"
          link="https://app.herewith.com/jobs?sort=price&order=ASC&page=1&per_page=20"
        />
      </Paper>
      <img
        src={curve}
        alt="f"
        // style={{ width: "100%", position: "relative", bottom: 0 }}
      />
    </Box>
  );
}
