import * as React from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  text: string;
  url: string;
}

const FooterLink: React.FC<LinkProps> = ({ text, url }) => {
  return (
    <Link
      style={{
        textDecoration: "none",
        margin: "1rem 0",
        color: "black",
        fontSize: "1.1rem",
      }}
      to={url}
    >
      {text}
    </Link>
  );
};

export default FooterLink;
